<div class="pdf-guide-container">
    <object class="pdf-guide-custom" data="./../assets/instructions/test.pdf" type="application/pdf">
        <p class="pdf-guide-text">We cannot preview the user guide. Please download the document to view it:
            <a href="#" (click)="downloadPdf()">Download user guide here!</a>
        </p>
        <div class="button-holder">
            <button class="download-button" (click)="downloadPdf()">
                <img src="./../assets/download_blkcircle.svg" alt="download pdf here">
            </button>
        </div>
    </object>
</div>