/* eslint-disable complexity */
import { Component } from '@angular/core'

import { environment } from '../../environments/environment'

@Component({
  selector: 'external-crewnet-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.css'],
})
export class ErrorPageComponent {
  currentYear = new Date().getFullYear()
  loginUri = environment.oidc.loginUri
  crewnetUri = environment.oidc.crewnetUri
}
