<div id="app">
    <div class="utilBar"></div>
    <div class="customMenu">
        <div class="customHeader">
            <div class="image-group">
                <a href="https://www.vanguard.com" target="_blank" class="customLink" title="The Vanguard Group"
                    aria-label="The Vanguard Group" aria-describedby="navBarLogo_desc">
                    <img class="customHeaderImg" src="./../assets/VG_WM_S_RGB_v3.png" alt="vanguard logo">
                    <div id="navBarLogo_desc" class="d-none">Opens in a new window</div>
                </a>
            </div>
        </div>
    </div>
    <div class="centerContent">
        <div id="home" class="one-column-layout" role="main" aria-label="Main content" tabindex="0">
            <div class="error-page">
                <div class="content-text">
                    <h1 class="image-header-text">Vanguard Portal</h1>
                    <div class="text-container">
                        <p class="error-text">Are you looking for Crewnet?</p>
                        <p class="inline-text">Connect using a Vanguard-issued system and verify the internal network
                            connection. Then, <a href="{{crewnetUri}}" class="login-link" title="Go to Crewnet"
                            aria-label="Go to crewnet">
                            click here
                        </a> or use the browser Home button to access CrewNet</p>
                    </div>
                    <div class="link-container">
                        <p class="image-text">Are you looking for the <span class="text-font">Retiree Portal? </span>
                        </p>
                        <a href="{{loginUri}}" class="login-link redirect-link" title="Log on to retiree portal"
                            aria-label="Go to retiree portal">
                            Go to Retiree Portal
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="customFooter" role="contentinfo">
        <a href="https://www.vanguard.com" target="_blank" title="The Vanguard Group" aria-label="The Vanguard Group"
            aria-describedby="navBarLogo_desc">
            <img class="customFooterImg" src="./../assets/VG_WM_S_RGB_v3.png">
            <div id="navBarLogo_desc" class="d-none">Opens in a new window</div>
        </a>
        <p>© 2000–{{ currentYear }} The Vanguard Group, Inc. All rights reserved.
        </p>
        <p>Your use of this site signifies that you accept our <a class="customFooterLink"
                href="https://investor.vanguard.com/terms-conditions" title="Terms and conditions of use"
                aria-label="Terms and conditions of use">Terms and conditions of use.</a>
        </p>
        <p><a class="customFooterLink" href="https://investor.vanguard.com/security" title="Security"
                aria-label="Security">Security.</a></p>
    </div>
</div>