import { Inject, Injectable } from '@angular/core'
//import { Router } from '@angular/router'
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular'
import { OktaAuth } from '@okta/okta-auth-js'

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public oktaAuthStateService: OktaAuthStateService,
  ) {}

  async setCookie(name: string, value: string, domain: string) {
    return new Promise<void>((resolve) => {
      document.cookie = `${name}=${value}; domain=${domain}`
      resolve()
    })
  }

  async getCookie(key: string) {
    const cookies = document.cookie.split(';')
    const accessTokenCookie = cookies.find((cookie) =>
      cookie.trim().startsWith(`${key}=`),
    )
    if (accessTokenCookie) {
      const accessTokenStr = accessTokenCookie.split('=')[1]
      const accessToken = decodeURIComponent(accessTokenStr)
      return accessToken
    } else {
      return null
    }
  }

  async getAllCookies(): Promise<{ [key: string]: string }> {
    return new Promise((resolve) => {
      const cookies: { [key: string]: string } = {}
      document.cookie.split(';').forEach((cookie) => {
        const [name, value] = cookie.trim().split('=')
        cookies[name] = decodeURIComponent(value)
      })
      if (Object.keys(cookies).length > 0) {
        console.log('got cookies')
      } else {
        console.log('No cookie found')
      }
      resolve(cookies)
    })
  }

  async deleteCookie(key: string) {
    document.cookie = `${key}=; domain=.vanguard.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; `
  }
}
