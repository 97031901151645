<div class="pdf-viewer-container">
    <pdf-viewer [src]="pdfSrc" [show-all]="true" [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'"
        [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'" [autoresize]="true"
        [show-borders]="true" class="pdf-viewer-custom">
    </pdf-viewer>
    <div class="pdf-toolbar">
        <button class="toolbar-button" (click)="downloadPdf()">
            <img src="./../assets/download_circle.svg" alt="download pdf here">
        </button>
        <button class="toolbar-button" (click)="print()">
            <img src="./../assets/print_icon.svg" alt="print pdf here">
        </button>
    </div>
</div>