import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular'
import { IDToken, OktaAuth } from '@okta/okta-auth-js'

import { environment } from '../../environments/environment'
import { CookieService } from './cookie.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public oktaAuthStateService: OktaAuthStateService,
    private cookieService: CookieService,
    private router: Router,
  ) {}

  async login() {
    await this.oktaAuth.signInWithRedirect()
  }

  logout() {
    this.oktaAuth.signOut()
  }

  async handleAuthentication() {
    const isAuthenticated = await this.oktaAuth.isAuthenticated()
    console.log('auth check')
    if (isAuthenticated) {
      console.log('auth successful')
      const accessToken = await this.oktaAuth.getAccessToken()
      const domain = '.vanguard.com'
      await this.cookieService.setCookie('accessToken', accessToken, domain)
      //   document.cookie = `accessToken=${accessToken}; domain=${domain}`
      this.cookieService.getCookie('accessToken')
      this.cookieService.getAllCookies()
      this.reloadPage()
    }
  }

  reloadPage() {
    const isReloaded =
      localStorage.getItem('checkReload') != null &&
      localStorage.getItem('checkReload') === 'true'
    if (!isReloaded) {
      localStorage.setItem('checkReload', 'true')
      localStorage.setItem('checkRedirect', 'true')
      console.log(
        'local storage: ',
        localStorage.getItem('checkReload'),
        localStorage.getItem('checkRedirect'),
      )
      window.location.reload()
    }
  }

  redirectToAem() {
    const aemUri = environment.oidc.aemUri
    window.location.href = aemUri
    //this.router.navigateByUrl(aemUri)
  }

  pageReloadCheck() {
    const isReloaded = localStorage.getItem('checkReload') === 'true'
    if (isReloaded) {
      localStorage.removeItem('checkReload')
    }
  }

  async getUser(): Promise<{ [key: string]: any }> {
    const isUserAuthenticated = await this.oktaAuth.isAuthenticated()
    if (isUserAuthenticated) {
      const idToken = (await this.oktaAuth.tokenManager.get(
        'idToken',
      )) as IDToken
      const userInfo = idToken.claims
      const userInfoMap = this.mapUserInfo(userInfo)
      return userInfoMap
    }
  }

  async tempRedirect() {
    const isAuthenticated = await this.oktaAuth.isAuthenticated()
    if (isAuthenticated) {
      this.router.navigate(['/loader'])
    }
  }

  private mapUserInfo(userInfo: any): { [key: string]: any } {
    const userInfoMap: { [key: string]: any } = {}
    for (const key in userInfo) {
      if (userInfo.hasOwnProperty(key)) {
        userInfoMap[key] = userInfo[key]
      }
    }
    return userInfoMap
  }

  async checkAuthForLogOut() {
    const isUserAuthenticated = await this.oktaAuth.isAuthenticated()
    if (isUserAuthenticated) {
      this.logout()
    } else {
      this.router.navigate(['/logout'])
    }
  }
}
