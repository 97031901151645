/* eslint-disable complexity */
import { HttpClient } from '@angular/common/http'
import { Component } from '@angular/core'
import { saveAs } from 'file-saver'

@Component({
  selector: 'external-crewnet-loginmanual',
  templateUrl: './loginmanual.component.html',
  styleUrls: ['./loginmanual.component.css'],
})
export class LoginManualComponent {
  constructor(private http: HttpClient) {}
  pdfSrc = './../assets/instructions/LoginManualUpdated.pdf'
  downloadPdf() {
    this.http
      .get(this.pdfSrc, { responseType: 'blob' })
      .subscribe((response) => {
        saveAs(response, 'External CrewNet Login Instructions.pdf')
      })
  }
  print() {
    window.print()
  }
}
