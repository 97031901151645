import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { BrowserModule } from '@angular/platform-browser'
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular'
import { OktaAuth } from '@okta/okta-auth-js'
import { LinkModule } from '@vg-constellation/angular-16/link'
import { SpinnerModule } from '@vg-constellation/angular-16/spinner'
import { PdfViewerModule } from 'ng2-pdf-viewer'

import { environment } from './../environments/environment'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { ErrorPageComponent } from './errorpage/errorpage.component'
import { GuidebookComponent } from './guidebook/guidebook.component'
import { HomeComponent } from './home/home.component'
import { LoadComponent } from './loader/loader.component'
import { LoginManualComponent } from './loginmanual/loginmanual.component'
import { LogoutComponent } from './logout/logout.component'
import { ManualComponent } from './manual/manual.component'
import { PasswordResetComponent } from './passwordreset/passwordreset.component'
import { ProfileComponent } from './profile/profile.component'
import { AuthService } from './services/auth.service'
import { UKManualComponent } from './ukmanual/ukmanual.component'

const oktaAuth = new OktaAuth(environment.oidc)

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LogoutComponent,
    LoadComponent,
    ProfileComponent,
    ManualComponent,
    GuidebookComponent,
    ErrorPageComponent,
    LoginManualComponent,
    PasswordResetComponent,
    UKManualComponent,
  ],
  imports: [
    BrowserModule,
    OktaAuthModule,
    CommonModule,
    AppRoutingModule,
    LinkModule,
    SpinnerModule,
    MatButtonModule,
    MatIconModule,
    PdfViewerModule,
    HttpClientModule,
  ],
  providers: [{ provide: OKTA_CONFIG, useValue: { oktaAuth } }, AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {}
