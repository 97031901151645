import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { OktaCallbackComponent } from '@okta/okta-angular'

import { ErrorPageComponent } from './errorpage/errorpage.component'
import { GuidebookComponent } from './guidebook/guidebook.component'
import { HomeComponent } from './home/home.component'
import { LoadComponent } from './loader/loader.component'
import { LoginManualComponent } from './loginmanual/loginmanual.component'
import { ManualComponent } from './manual/manual.component'
import { PasswordResetComponent } from './passwordreset/passwordreset.component'
import { UKManualComponent } from './ukmanual/ukmanual.component'
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'logout',
    component: HomeComponent,
  },
  {
    path: 'signOff',
    component: LoadComponent,
  },
  {
    path: 'loader',
    component: LoadComponent,
  },
  {
    path: 'logon',
    component: HomeComponent,
  },
  // {
  //   path: 'profile',
  //   component: ProfileComponent,
  // },
  {
    path: 'userManual',
    component: ManualComponent,
  },
  {
    path: 'userGuide',
    component: GuidebookComponent,
  },
  {
    path: 'portal',
    component: ErrorPageComponent,
  },
  {
    path: 'loginManual',
    component: LoginManualComponent,
  },
  {
    path: 'passwordReset',
    component: PasswordResetComponent,
  },
  {
    path: 'UKuserManual',
    component: UKManualComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
