import { Component } from '@angular/core'

import { environment } from './../environments/environment'

@Component({
  selector: 'external-crewnet-spa-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'external-crewnet-spa'
  public environment = environment

  currentYear = new Date().getFullYear()
}
