import { internal } from './environment.common'
import { production } from './environment.production'

export const environment = {
  ...production,
  ...internal,
  oidc: {
    clientId: '0oa1pkj0jo8bG5kQf1d8',
    issuer: 'https://vgius.okta.com/oauth2/default',
    redirectUri: `${window.location.origin}/login/callback`,
    postLogoutRedirectUri: `${window.location.origin}/logout`,
    scopes: ['openid', 'profile', 'email'],
    testing: {
      disableHttpsCheck: false,
    },
    aemUri: 'https://externalcrewnet.vanguard.com/aem',
    loginUri: `${window.location.origin}`,
    crewnetUri: 'https://crewnet.vanguard.com/',
  },
}
