/* eslint-disable complexity */
import { AfterViewInit, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from '../services/auth.service'
import { CookieService } from '../services/cookie.service'

@Component({
  selector: 'external-crewnet-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements AfterViewInit, OnInit {
  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
  ) {}
  currentYear = new Date().getFullYear()
  errorOnRedirect = false
  ngOnInit() {
    this.errorOnRedirect = this.router.url === '/logon'
  }
  ngAfterViewInit(): void {
    const currentPath = this.router.url
    localStorage.removeItem('checkReload')
    localStorage.removeItem('checkRedirect')
    this.cookieService.deleteCookie('accessToken')

    if (currentPath !== '/logon') {
      this.authService.tempRedirect()
    }
  }
  login() {
    this.authService.login()
  }
}
