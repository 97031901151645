<div id="app">
    <div class="utilBar">
        <p class="banner"><span class="banner-text text-line"><span class="text-font">New to the Retiree Portal?</span> You need to activate your account
            via activation email and follow these <a class="login-link redirect-link" href="/userManual"
                target="_blank">registration instructions</a>.
            If you are a retiree and did not receive an activation email, contact
                Crew Central at 844-841-2739.</span>
            <span class="banner-text text-line"><span class="text-font">Already registered? </span>Use these <a
                    class="login-link redirect-link" href="/loginManual" target="_blank">login instructions</a> to log
                into the Retiree
                Portal.</span>
            <span class="banner-text text-line"><span class="text-font">Forgot password? </span>Use these <a
                class="login-link redirect-link" href="/passwordReset" target="_blank">password reset instructions</a> to reset your password.</span>
            <span class="banner-text"><span class="text-font">Active employee? </span>The CrewNet External Retiree Portal is available to US Vanguard retirees only. Payslips and benefits documents can be accessed through Vanguard's CrewNet intranet.</span>
        </p>
    </div>
    <div class="customMenu">
        <div class="customHeader">
            <div class="image-group">
                <a href="https://www.vanguard.com" target="_blank" class="customLink" title="The Vanguard Group"
                    aria-label="The Vanguard Group" aria-describedby="navBarLogo_desc">
                    <img class="custom" src="./../assets/VanguardVLogo.svg" alt="vanguard logo">
                    <div id="navBarLogo_desc" class="d-none">Opens in a new window</div>
                </a>
                <a href="/" class="customLink divider" title="Retiree Portal Home" aria-label="Retiree Portal Home">
                    <img src="./../assets/RetireePortal.svg" alt="retiree portal image">
                </a>
            </div>
        </div>
    </div>
    <div class="centerContent">
        <div id="home" class="two-column-layout" role="main" aria-label="Main content" tabindex="0">
            <div class="left-column">
                <div class="image-content-text">
                    <div *ngIf="errorOnRedirect" class="error-message">
                        <img src="./../assets/warning.svg" alt="error warning" class="warning-image">
                        Error! Something went wrong. Please try logon again.
                    </div>
                    <h1 class="image-header-text">Welcome to Vanguard's Retiree portal</h1>
                    <div class="button-container">
                        <p class="image-text">Enjoy extended access to Vanguard news, information, and HR systems to
                            stay engaged with Vanguard and
                            Crew
                            Central throughout your retirement years.</p>
                        <a href="#" (click)="login()" class="login-link" title="Log on to retiree portal"
                            aria-label="Log on to retiree portal"><img src="./../assets/Website.svg" class="login-image"
                                alt="Logon image">
                            Log On
                        </a>
                    </div>
                </div>
            </div>
            <div class="right-column">
                <img class="ui image portalImg" src="./../assets/vgPortalImage2.jpeg"
                    alt="Mature couple using a laptop while relaxing at home">
            </div>
        </div>
    </div>
    <div class="customFooter" role="contentinfo">
        <a href="https://www.vanguard.com" target="_blank" title="The Vanguard Group" aria-label="The Vanguard Group"
            aria-describedby="navBarLogo_desc">
            <img class="customFooterImg" src="./../assets/VG_WM_S_RGB_v3.png">
            <div id="navBarLogo_desc" class="d-none">Opens in a new window</div>
        </a>
        <p>© 2000–{{ currentYear }} The Vanguard Group, Inc. All rights reserved.
        </p>
        <p>Your use of this site signifies that you accept our <a class="customFooterLink"
                href="https://investor.vanguard.com/terms-conditions" title="Terms and conditions of use"
                aria-label="Terms and conditions of use">Terms and conditions of use.</a>
        </p>
        <p><a class="customFooterLink" href="https://investor.vanguard.com/security" title="Security"
                aria-label="Security">Security.</a></p>
    </div>
</div>