/* eslint-disable complexity */
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from '../services/auth.service'

@Component({
  selector: 'external-crewnet-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoadComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    const currentPath = this.router.url
    if (currentPath === '/loader') {
      console.log('user auth check')
      this.authService.handleAuthentication()
      const isRedirected =
        localStorage.getItem('checkRedirect') != null &&
        localStorage.getItem('checkRedirect') === 'true'

      if (isRedirected) {
        console.log('user redirect')
        this.authService.redirectToAem()
      }
    }
    if (currentPath === '/signOff') {
      this.authService.checkAuthForLogOut()
    }
  }
}
