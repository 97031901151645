/* eslint-disable complexity */
import { HttpClient } from '@angular/common/http'
import { Component } from '@angular/core'
import { saveAs } from 'file-saver'

@Component({
  selector: 'external-crewnet-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.css'],
})
export class PasswordResetComponent {
  constructor(private http: HttpClient) {}
  pdfSrc = './../assets/instructions/PasswordResetManual.pdf'

  downloadPdf() {
    this.http
      .get(this.pdfSrc, { responseType: 'blob' })
      .subscribe((response) => {
        saveAs(response, 'Portal Password Reset Instructions.pdf')
      })
  }
  print() {
    window.print()
  }
}
